import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Row, Column, Box, Button, Text } from 'components'
import { getLeadPage2ValidationSchema } from 'utils/forms'
import loadable from '@loadable/component'

import MultiSelect from 'components/MultiSelect'

import { Radio, Label } from 'components/Forms'

import { Badge } from 'components/Forms/Forms'

const Select = loadable(() => import('components/Forms/BambusSelect'))

const ControlWrapper = (props) => {
  return <Box {...props} sx={{ padding: '12px 0' }} />
}

const ControlHeading = ({ children, extraElement = null }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
      <Text
        sx={{
          fontWeight: '600',
        }}
      >
        {children}
      </Text>
      {extraElement}
    </Box>
  )
}

const RadioVariant1 = React.forwardRef(({ name, value, label }, ref) => {
  const id = `${name}-${value}`
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
      }}
    >
      <Box
        as="input"
        type="radio"
        name={name}
        id={id}
        value={value}
        ref={ref}
        sx={{
          opacity: 0,
          width: 0,
          height: 0,
          '&:checked + label': {
            borderColor: 'transparent',
            backgroundColor: 'colorBlue100',
            color: 'white',
          },
        }}
      />
      <Label
        color="colorBlue100"
        htmlFor={id}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '12px',
          cursor: 'pointer',
          border: '1px solid',
          borderColor: 'colorBlue25',
          borderRadius: 'default',
          fontSize: [1, 2],
          textAlign: ['center', 'unset'],
        }}
      >
        {label}
      </Label>
    </Box>
  )
})

const LEAD_SOURCE_SURVEY_OPTIONS = [
  {
    value: 'recommendation',
    label: 'Empfehlung',
  },
  {
    value: 'press',
    label: 'Presse',
  },
  {
    value: 'radio',
    label: 'Radio',
  },
  {
    value: 'tv',
    label: 'TV',
  },
  {
    value: 'facebook',
    label: 'Facebook',
  },
  {
    value: 'google',
    label: 'Google',
  },
  {
    value: 'mail',
    label: 'Post',
  },
  {
    value: 'partner',
    label: 'Partner',
  },
  {
    value: 'not_specified',
    label: 'Keine Angabe',
  },
]

const LeadModalPage2 = ({
  onSubmit: onSubmitProp = () => {},
  isLoading = false,
  signUpServiceDimension = 'classic_mortgage',
  didUserSubmitPhoneNumber = true,
}) => {
  const { register, handleSubmit, setValue, watch } = useForm({
    resolver: getLeadPage2ValidationSchema(
      signUpServiceDimension,
      didUserSubmitPhoneNumber
    ),
    defaultValues: {
      requires_fast_processing: '',
      new_property_stage: null,
      reachability_hours: null,
      lead_source_survey: null,
    },
  })

  useEffect(() => {
    register('lead_source_survey')
    register('reachability_hours')
  }, [])

  const onSubmit = (data) => {
    if (!data.requires_fast_processing) {
      delete data['requires_fast_processing']
    }
    if (!data.new_property_stage) {
      delete data['new_property_stage']
    }
    if (!data.reachability_hours) {
      delete data['reachability_hours']
    }
    if (!data.lead_source_survey) {
      delete data['lead_source_survey']
    }

    onSubmitProp({
      ...data,
      ...(data.requires_fast_processing && {
        requires_fast_processing: data.requires_fast_processing === 'true',
      }),
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Text mb="10px">
        Vielen Dank für Ihre Anfrage! Mit den folgenden optionalen Angaben
        helfen Sie uns Ihr Vorhaben besser zu bearbeiten:
      </Text>

      <ControlWrapper>
        <Row>
          <Column size={[1, 1, 1, 1]}>
            <ControlHeading>
              Haben Sie es eilig? Gerne bearbeiten wir Ihren Antrag kostenlos im
              Eilverfahren.
            </ControlHeading>
            <Box
              sx={{
                display: 'flex',
                position: 'relative',
                justifyContent: ['center', null, 'unset'],
                maxWidth: ['unset', '335px'],
              }}
            >
              <Label
                sx={{
                  padding: '12px',
                  cursor: 'pointer',
                  width: 'auto',
                  border: '1px solid',
                  borderColor: 'colorBlue25',
                  borderRadius: 'default',
                  borderTopRightRadius: '0',
                  borderBottomRightRadius: '0',
                  fontSize: [1, 2],
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: ['column', 'row'],
                  alignItems: 'center',
                  textAlign: ['center', 'start'],
                }}
                color="colorBlue100"
              >
                <Radio
                  name="requires_fast_processing"
                  id="requires_fast_processing-true"
                  value="true"
                  ref={register}
                  sx={{
                    color: 'colorBlue100',
                    width: '20px',
                    marginRight: ['0px', '8px'],
                  }}
                />
                Ja
              </Label>

              <Label
                sx={{
                  padding: '12px',
                  cursor: 'pointer',
                  width: 'auto',
                  border: '1px solid',
                  borderLeft: 'none',
                  borderColor: 'colorBlue25',
                  borderRadius: 'default',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  fontSize: [1, 2],
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: ['column', 'row'],
                  alignItems: 'center',
                  textAlign: ['center', 'start'],
                }}
                color="colorBlue100"
              >
                <Radio
                  name="requires_fast_processing"
                  id="requires_fast_processing-false"
                  value="false"
                  ref={register}
                  sx={{
                    color: 'colorBlue100',
                    width: '20px',
                    marginRight: ['0px', '8px'],
                  }}
                />
                Nein
              </Label>
              <Badge label="optional" />
            </Box>
          </Column>
        </Row>
      </ControlWrapper>

      {signUpServiceDimension === 'classic_mortgage' && (
        <ControlWrapper>
          <Row>
            <Column size={[1, 1, 1, 1]}>
              <ControlHeading
                extraElement={<Badge noPositioning label="optional" ml="5px" />}
              >
                Wo im Prozess des Immobilienkaufs stehen Sie gerade?
              </ControlHeading>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', null, '1fr 1fr'],
                  gap: '10px',
                }}
              >
                <RadioVariant1
                  value="just_looking"
                  label="Ich bin nur neugierig"
                  name="new_property_stage"
                  ref={register}
                />
                <RadioVariant1
                  name="new_property_stage"
                  value="active_search"
                  label="Ich schaue online nach Immobilien"
                  ref={register}
                />
                <RadioVariant1
                  name="new_property_stage"
                  value="visiting_properties"
                  label="Ich gehe bereits zu Besichtigungen"
                  ref={register}
                />
                <RadioVariant1
                  name="new_property_stage"
                  value="property_found"
                  label="Ich habe eine Immobilie gefunden"
                  ref={register}
                />
              </Box>
            </Column>
          </Row>
        </ControlWrapper>
      )}

      {didUserSubmitPhoneNumber && (
        <ControlWrapper>
          <Row>
            <Column size={[1, 1, 1, 1]}>
              <ControlHeading>
                Wann können wir Sie am besten erreichen?
              </ControlHeading>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: ['center', null, 'unset'],
                }}
              >
                <MultiSelect
                  badge="optional"
                  values={[
                    {
                      label: 'Vormittags',
                      value: 'mornings',
                    },
                    {
                      label: 'Mittags',
                      value: 'midday',
                    },
                    {
                      label: 'Nachmittags',
                      value: 'afternoon',
                    },
                  ]}
                  onChange={(v) => {
                    setValue('reachability_hours', v && v.toString())
                  }}
                />
              </Box>
            </Column>
          </Row>
        </ControlWrapper>
      )}

      <ControlWrapper>
        <Row>
          <Column
            size={[1, 1, 1, 1]}
            sx={{ maxWidth: ['unset', null, '367px'] }}
          >
            <ControlHeading>Wie haben Sie uns gefunden?</ControlHeading>
            <Select
              options={LEAD_SOURCE_SURVEY_OPTIONS}
              onChange={(option) => {
                setValue('lead_source_survey', option.value, {
                  shouldValidate: true,
                })
              }}
              badge="optional"
              placeholder="Wählen..."
              isSearchable={false}
            />
          </Column>
        </Row>
      </ControlWrapper>

      <Box mt="18px">
        <Button
          id="modalRequestComplete"
          variant="main"
          type="submit"
          loading={isLoading}
          disabled={isLoading}
        >
          Absenden
        </Button>
      </Box>
    </form>
  )
}

export default LeadModalPage2
